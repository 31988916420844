import LogRocket from 'logrocket'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

const initialState = {
    listeners: {},
    lang: 'en',
    auth: {
        initting: true,
        loading: false,
        error: null,
        data: null,
    },
    plans: {
        loading: false,
        error: null,
        data: {},
    },
    activeTeamId: null,
    refresh_token: null,
    teams: {
        loading: false,
        error: null,
        data: {},
    },
    members: {
        loading: false,
        error: null,
        data: {},
    },
    invites: {
        loading: false,
        error: null,
        data: {},
    },
    meta: {
        loading: false,
        error: null,
        data: {},
    },
    settings: {
        loading: false,
        error: null,
        data: {},
    },
    activity: {
        loadingMore: false,
        moreAvailable: true,
        loading: false,
        error: null,
        data: {},
    },
    invoices: {
        loading: false,
        error: null,
        data: {},
    },
    currentUsage: {
        loading: false,
        error: null,
        data: null,
    },
    pastInvoices: {
        loadingMore: false,
        moreAvailable: true,
        loading: false,
        error: null,
        data: {},
    },
    activeShow: null,
    activeEpisode: null,
    paymentMethods: {
        loading: false,
        error: null,
        data: {},
    },
    shows: {
        loading: false,
        error: null,
        data: {},
    },
    showEpisodes: {},
    showUsage: {},
}

const reducer = (state, action) => {
    switch (action.type) {
        //
        //Auth
        //
        case `refresh_token/on_data`: {
            return {
                ...state,
                refresh_token: action.data,
            }
        }
        case `auth/fetch_complete`: {
            let result = { ...state }
            if (!action.data) {
                //Means we logged out. clear everything
                result = { ...initialState }
            }
            result.auth = { ...result.auth, initting: false, loading: false, data: action.data }

            const key = window.localStorage.getItem('activeTeamId')
            if (action.data) {
                if (key && key !== 'null') {
                    result.activeTeamId = key
                } else {
                    result.activeTeamId = action.data.id
                }
            }
            return result
        }
        case `auth/fetch_start`: {
            //@Todo whenever auth changes we should also clear up EVERYTHING liks shows n stuff
            return {
                ...initialState,
                auth: { initting: false, loading: true, error: null, data: null },
            }
        }
        case `auth/fetch_failed`: {
            return {
                ...initialState,
                activeTeamId: null,
                auth: { ...state.auth, loading: false, error: action.error, data: null },
            }
        }
        //
        //Org Current Month Usage
        //
        case `current_usage/reset`: {
            return {
                ...state,
                currentUsage: { loading: true, error: null, data: null },
            }
        }
        case `current_usage/on_data`: {
            return {
                ...state,
                currentUsage: { ...state.currentUsage, loading: false, data: action.data },
            }
        }
        //
        //Teams
        //
        case `teams/reset`: {
            return {
                ...state,
                teams: {
                    loading: true,
                    error: null,
                    data: {},
                },
            }
        }
        case `teams/on_data`: {
            const result = {
                ...state,
                teams: {
                    loading: false,
                    data: action.data,
                },
            }
            if (!state.activeTeamId) {
                const key = window.localStorage.getItem('activeTeamId')
                if (key && key !== 'null') {
                    result.activeTeamId = key
                }
            }
            return result
        }
        case `teams/switch`: {
            return {
                ...state,
                activeTeamId: action.id,
            }
        }
        //
        //Org Team Members
        //
        case `members/reset`: {
            return {
                ...state,
                members: {
                    loading: true,
                    error: null,
                    data: {},
                },
            }
        }
        case `members/on_data`: {
            return {
                ...state,
                members: {
                    ...state.members,
                    data: action.data,
                },
            }
        }
        case `invites/reset`: {
            return { ...state, invites: { loading: true, error: null, data: {} } }
        }
        case `invites/on_data`: {
            return {
                ...state,
                invites: {
                    ...state.invites,
                    data: action.data,
                },
            }
        }
        //
        //Org Past Invoices
        //
        case `past_invoices/reset`: {
            return {
                ...state,
                pastInvoices: {
                    loadingMore: false,
                    moreAvailable: true,
                    loading: true,
                    error: null,
                    data: {},
                },
            }
        }
        case `past_invoices/on_data`: {
            return {
                ...state,
                pastInvoices: {
                    ...state.pastInvoices,
                    data: { ...state.pastInvoices.data, ...action.data },
                },
            }
        }
        case `past_invoices/fetch_start`: {
            return {
                ...state,
                pastInvoices: {
                    ...state.pastInvoices,
                    loading: !state.pastInvoices.cursor,
                    loadingMore: !!state.pastInvoices.cursor,
                    error: null,
                },
            }
        }
        case `past_invoices/fetch_complete`: {
            return {
                ...state,
                pastInvoices: {
                    loading: false,
                    error: null,
                    data: { ...state.pastInvoices.data, ...action.data },
                    loadingMore: false,
                    moreAvailable: action.moreAvailable,
                    cursor: action.cursor,
                },
            }
        }
        case `past_invoices/fetch_failed`: {
            return {
                ...state,
                pastInvoices: { ...state.pastInvoices, loading: false, error: action.error },
            }
        }
        //
        //Meta
        //
        case `meta/reset`: {
            return {
                ...state,
                meta: { loading: true, error: null, data: null },
            }
        }
        case `meta/on_data`: {
            return {
                ...state,
                meta: { loading: false, error: null, data: action.data },
            }
        }
        //
        //Settings
        //
        case `settings/reset`: {
            return {
                ...state,
                settings: { loading: true, error: null, data: null },
            }
        }
        case `settings/on_data`: {
            return {
                ...state,
                settings: { loading: false, error: null, data: action.data },
            }
        }
        //
        //Plans
        //
        case `plans/reset`: {
            return {
                ...state,
                plans: { loading: true, error: null, data: {} },
            }
        }
        case `plans/on_data`: {
            return {
                ...state,
                plans: { loading: false, error: null, data: action.data },
            }
        }
        //
        //Payment Methods
        //
        case `payment_methods/reset`: {
            return {
                ...state,
                paymentMethods: { loading: true, error: null, data: {} },
            }
        }
        case `payment_methods/on_data`: {
            return {
                ...state,
                paymentMethods: { loading: false, error: null, data: action.data },
            }
        }
        case `payment_method/set_card_default_start`: {
            const newData = state.paymentMethods.data
            newData[action.id].loading = true
            newData[action.id].error = null
            return {
                ...state,
                paymentMethods: { ...state.paymentMethods, data: newData },
            }
        }
        case `payment_method/set_card_default_complete`: {
            const newData = state.paymentMethods.data
            newData[action.id].loading = false
            newData[action.id].error = null
            return {
                ...state,
                paymentMethods: { ...state.paymentMethods, data: newData },
            }
        }
        case `payment_method/set_card_default_failed`: {
            const newData = state.paymentMethods.data
            newData[action.id].loading = false
            newData[action.id].error = action.error
            return {
                ...state,
                paymentMethods: { ...state.paymentMethods, data: newData },
            }
        }
        case `payment_method/delete_start`: {
            const newData = state.paymentMethods.data
            newData[action.id].loading = true
            newData[action.id].deleting = true
            newData[action.id].error = null
            return {
                ...state,
                paymentMethods: { ...state.paymentMethods, data: newData },
            }
        }
        case `payment_method/delete_complete`: {
            const newData = state.paymentMethods.data
            delete newData[action.id]
            return {
                ...state,
                paymentMethods: { ...state.paymentMethods, data: newData },
            }
        }
        case `payment_method/delete_failed`: {
            const newData = state.paymentMethods.data
            newData[action.id].deleting = false
            newData[action.id].loading = false
            newData[action.id].error = action.error
            return {
                ...state,
                paymentMethods: { ...state.paymentMethods, data: newData },
            }
        }
        //
        //Shows
        //
        case `show/make_active`: {
            return { ...state, activeShowId: action.id }
        }
        case `shows/reset`: {
            return {
                ...state,
                shows: { loading: true, error: null, data: {} },
            }
        }
        case `shows/on_data`: {
            return {
                ...state,
                shows: { loading: false, error: null, data: action.data },
            }
        }
        //
        //Listeners
        //
        case `listener/add`: {
            //If existing listener, delete that
            const origList = state.listeners[action.name]
            if (origList) {
                origList()
            }

            const newState = {
                ...state,
                listeners: {
                    ...state.listeners,
                    [action.name]: action.handler,
                },
            }
            return newState
        }
        case `listener/cleanup_show`: {
            const newListeners = { ...state.listeners }
            const usageH = state.listeners[`usage-${action.id}`]
            if (usageH) {
                usageH()
                delete newListeners[`usage-${action.id}`]
            }
            const episodeH = state.listeners[`episodes-${action.id}`]
            if (episodeH) {
                episodeH()
                delete newListeners[`episodes-${action.id}`]
            }

            return {
                ...state,
                listeners: newListeners,
            }
        }
        case `listener/cleanup`: {
            Object.entries(state.listeners).forEach(([name, handler]) => {
                if (handler) handler()
            })

            return {
                ...state,
                listeners: {},
            }
        }
        //
        //Show Usage
        //
        case `showUsage/reset`: {
            return {
                ...state,
                showUsage: {
                    ...state.showUsage,
                    [action.showId]: {
                        loading: true,
                        error: null,
                        data: {},
                    },
                },
            }
        }
        case `showUsage/on_data`: {
            return {
                ...state,
                showUsage: {
                    ...state.showUsage,
                    [action.showId]: {
                        loading: false,
                        error: null,
                        data: action.data,
                    },
                },
            }
        }
        //
        //Episodes
        //
        case `episode/make_active`: {
            return { ...state, activeEpisodeId: action.id }
        }
        case `showEpisodes/reset`: {
            return {
                ...state,
                showEpisodes: {
                    ...state.showEpisodes,
                    [action.showId]: {
                        loading: true,
                        error: null,
                        data: {},
                    },
                },
            }
        }
        case `showEpisodes/on_data`: {
            return {
                ...state,
                showEpisodes: {
                    ...state.showEpisodes,
                    [action.showId]: {
                        loading: false,
                        error: null,
                        data: action.data,
                    },
                },
            }
        }
        //
        //Activity
        //
        case `activity/fetch_start`: {
            return {
                ...state,
                activity: {
                    ...state.activity,
                    loadingMore: true,
                    error: null,
                },
            }
        }
        case `activity/fetch_complete`: {
            return {
                ...state,
                activity: {
                    ...state.activity,
                    cursor: action.cursor,
                    error: null,
                    data: { ...state.activity.data, ...action.data },
                    loadingMore: false,
                    moreAvailable: action.moreAvailable,
                },
            }
        }
        case `activity/fetch_failed`: {
            return {
                ...state,
                activity: { ...state.activity, loadingMore: false, error: action.error },
            }
        }
        case `activity/reset`: {
            return {
                ...state,
                activity: {
                    loadingMore: false,
                    moreAvailable: true,
                    loading: false,
                    error: null,
                    data: {},
                },
            }
        }
        case `activity/on_data`: {
            return {
                ...state,
                activity: {
                    ...state.activity,
                    loading: false,
                    error: null,
                    data: { ...state.activity.data, ...action.data },
                },
            }
        }
        case `lang/on_change`: {
            return {
                ...state,
                lang: action.lang,
            }
        }
        default: {
            return state
        }
    }
}

const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
          })
        : compose

const logger = store => next => action => {
    //@Todo this is only a placeholder to show how to
    //do middlewares
    let result = next(action)
    return result
}

const enhancer = composeEnhancers(applyMiddleware(thunk, logger, LogRocket.reduxMiddleware()))
export const store = createStore(reducer, initialState, enhancer)
