import React from 'react'
import firebase from '../lib/firebase'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { trackError, getExtensionFromFileObject } from '../util'
import LogRocket from 'logrocket'
import i18next from 'i18next'

//@Todo this is not used. we direclty set the lang in header.js
export const changeLang = lang => {
    i18next.changeLanguage(lang)
    return dispatch => {
        dispatch({ type: 'lang/on_change', lang })
    }
}

export const useEpisodeAnalytics = (showId, episodeId, range) => {
    const activeTeamId = useSelector(s => s.activeTeamId)
    const [loading, setLoading] = React.useState(true)
    const [analytics, setAnalytics] = React.useState(null)

    React.useEffect(() => {
        if (!activeTeamId) return

        setLoading(true)
        firebase
            .firestore()
            .doc(
                `accounts/${activeTeamId}/shows/${showId}/episodes/${episodeId}/analytics/${range}`,
            )
            .get()
            .then(snap => {
                if (snap.exists) {
                    setAnalytics(snap.data())
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }, [activeTeamId, showId, episodeId, range])
    return { analytics, loading }
}
export const useShowAnalytics = (showId, range) => {
    const activeTeamId = useSelector(s => s.activeTeamId)
    const [loading, setLoading] = React.useState(true)
    const [analytics, setAnalytics] = React.useState(null)

    React.useEffect(() => {
        if (!activeTeamId) return

        setLoading(true)
        firebase
            .firestore()
            .doc(`accounts/${activeTeamId}/shows/${showId}/analytics/${range}`)
            .get()
            .then(snap => {
                if (snap.exists) {
                    setAnalytics(snap.data())
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }, [activeTeamId, showId, range])
    return { analytics, loading }
}

export const subscribeShowUsage = showId => {
    return (dispatch, getState) => {
        const { activeTeamId } = getState()
        if (!activeTeamId) return

        const lName = `usage-${showId}`

        dispatch({ type: 'showUsage/reset', showId })

        const handler = firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/shows/${showId}/usage`)
            .orderBy('periodEnd', 'desc')
            .limit(1)
            .onSnapshot(
                snap => {
                    let data
                    if (!snap.empty) {
                        data = snap.docs[0].data()
                    }
                    dispatch({ type: 'showUsage/on_data', showId, data })
                },
                error => {
                    dispatch({ type: 'showUsage/on_data', showId, data: null })
                },
            )
        dispatch({ type: 'listener/add', name: lName, handler })
    }
}

export const useShowUsage = showId => {
    const activeTeamId = useSelector(s => s.activeTeamId)
    const [loading, setLoading] = React.useState(true)
    const [usage, setUsage] = React.useState([])

    React.useEffect(() => {
        if (!activeTeamId) return
        firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/shows/${showId}/usage`)
            .orderBy('periodEnd', 'desc')
            .limit(1)
            .get()
            .then(snap => {
                let usage
                if (!snap.empty) {
                    usage = snap.docs[0].data()
                }
                setUsage(usage)
                setLoading(false)
            })
    }, [activeTeamId, showId])
    return { usage, loading }
}
export const useUsage = () => {
    const activeTeamId = useSelector(s => s.activeTeamId)
    const [loading, setLoading] = React.useState(true)
    const [usage, setUsage] = React.useState([])

    React.useEffect(() => {
        if (!activeTeamId) return
        firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/usage`)
            .orderBy('from', 'desc')
            .limit(10)
            .get()
            .then(snap => {
                const usage = []
                snap.forEach(doc => usage.push({ id: doc.id, ...doc.data() }))
                setUsage(usage)
                setLoading(false)
            })
    }, [activeTeamId])
    return { usage, loading }
}
export const useInvoices = () => {
    const activeTeamId = useSelector(s => s.auth)
    const [loading, setLoading] = React.useState(true)
    const [invoices, setInvoices] = React.useState([])

    React.useEffect(() => {
        if (!activeTeamId) return
        firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/invoices`)
            .get()
            .then(snap => {
                const invoices = []
                snap.forEach(doc => invoices.push({ id: doc.id, ...doc.data() }))
                setInvoices(invoices)
                setLoading(false)
            })
    }, [activeTeamId])
    return { invoices, loading }
}

export const useEvents = () => {
    const activeTeamId = useSelector(s => s.activeTeamId)
    const [loading, setLoading] = React.useState(true)
    const [loadingMore, setLoadingMore] = React.useState(false)
    const [moreAvailable, setMoreAvailable] = React.useState(true)
    const [events, setEvents] = React.useState([])
    const [cursor, setCursor] = React.useState(null)

    const loadMore = () => {
        if (!activeTeamId) return
        setLoadingMore(true)

        let ref = firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/events`)
            .orderBy('created', 'desc')

        if (cursor) ref = ref.startAfter(cursor)

        ref.limit(15)
            .get()
            .then(snap => {
                const newEvents = []
                snap.forEach(doc => newEvents.push({ id: doc.id, ...doc.data() }))
                setEvents([...events, ...newEvents])
                setLoadingMore(false)
                if (snap.docs.length > 0) {
                    setCursor(snap.docs[snap.docs.length - 1])
                } else {
                    setMoreAvailable(false)
                }
            })
    }

    React.useEffect(() => {
        if (!activeTeamId) return

        let ref = firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/events`)
            .orderBy('created', 'desc')

        if (cursor) ref = ref.startAfter(cursor)

        ref.limit(15)
            .get()
            .then(snap => {
                const events = []
                snap.forEach(doc => events.push({ id: doc.id, ...doc.data() }))
                setEvents(events)
                setLoading(false)
                if (snap.docs.length > 0) {
                    setCursor(snap.docs[snap.docs.length - 1])
                } else {
                    setMoreAvailable(false)
                }
            })
    }, [activeTeamId, cursor])
    return { events, loading, loadMore, loadingMore, moreAvailable }
}

export const api = async (name, payload) => {
    try {
        const resp = await firebase.functions().httpsCallable(`api_${name}`)(payload)
        return resp.data
    } catch (err) {
        trackError(err)
        throw err
    }
}
export const renameWebsite = async (accountId, showId, websiteEndpoint) => {
    return api('website_rename', { accountId, websiteEndpoint, showId })
}

export const inviteMember = async (email, permissions) => {
    return api('member_invite', { email, permissions })
}
export const removeMember = async memberId => {
    const user = firebase.auth().currentUser
    if (!user || !user.uid) return
    return firebase.firestore().doc(`/accounts/${user.uid}/members/${memberId}`).delete()
}
export const removeInvite = async inviteId => {
    const user = firebase.auth().currentUser
    if (!user || !user.uid) return
    return firebase.firestore().doc(`/accounts/${user.uid}/invites/${inviteId}`).delete()
}
export const updateAccountSettings = async (accountId, data) => {
    return firebase
        .firestore()
        .doc(`/accounts/${accountId}/settings/default`)
        .set(data, { merge: true })
}

export const updatePermission = async (code, value, memberId, isMember, accountId) => {
    //@Todo this is bad, better to have a map of permissions instead of array.
    //and also do this on the backend api
    let permRef
    if (isMember) {
        permRef = firebase.firestore().doc(`/accounts/${accountId}/members/${memberId}`)
    } else {
        permRef = firebase.firestore().doc(`/accounts/${accountId}/invites/${memberId}`)
    }

    return firebase.firestore().runTransaction(txn => {
        return txn.get(permRef).then(doc => {
            if (!doc.exists) throw new Error('Member not found')

            const permObject = {}
            doc.data().permissions.forEach(p => {
                permObject[p] = true
            })
            permObject[code] = value

            const newPermissions = []
            Object.entries(permObject).forEach(([code, value]) => {
                if (value) newPermissions.push(code)
            })
            txn.update(permRef, { permissions: newPermissions })
        })
    })
}

export const setEpisodeArtwork = (accountId, showId, epId, artwork, progressCb) => {
    //@Todo use api or teams
    const user = firebase.auth().currentUser
    if (!user || !user.uid) return

    if (!artwork) return

    //@Todo handle edge cases in filename
    //artwork is a File object
    const ext = artwork.name.split('.').pop()
    const task = firebase
        .storage()
        .ref()
        .child(`${accountId}/${showId}/a/${epId}.${ext}`)
        .put(artwork)
    return new Promise((res, rej) => {
        task.on(
            'state_changed',
            snap => {
                if (progressCb) {
                    progressCb(snap.bytesTransferred / snap.totalBytes)
                }
            },
            err => {
                rej(err)
            },
            () => {
                res('Done')
            },
        )
    })
}
export const setEpisodeMedia = (accountId, showId, epId, media, progressCb) => {
    //@Todo use api or teams
    const user = firebase.auth().currentUser
    if (!user || !user.uid) return

    if (!media) return

    //@Todo handle edge cases in filename
    //artwork is a File object
    const ext = media.name.split('.').pop()
    const task = firebase
        .storage()
        .ref()
        .child(`${accountId}/${showId}/m/${epId}.${ext}`)
        .put(media)
    return new Promise((res, rej) => {
        task.on(
            'state_changed',
            snap => {
                if (progressCb) {
                    progressCb(snap.bytesTransferred / snap.totalBytes)
                }
            },
            err => {
                rej(err)
            },
            () => {
                res('Done')
            },
        )
    })
}

export const setShowArtwork = (accountId, showId, artwork, progressCb) => {
    if (!artwork) return

    const ext = getExtensionFromFileObject(artwork)

    const task = firebase
        .storage()
        .ref()
        .child(`${accountId}/${showId}/a/${showId}.${ext}`)
        .put(artwork)
    return new Promise((res, rej) => {
        task.on(
            'state_changed',
            snap => {
                progressCb && progressCb(snap.bytesTransferred / snap.totalBytes)
            },
            err => {
                rej(err)
            },
            () => {
                res('Done')
            },
        )
    })
}

export const useShows2 = () => {
    const activeTeamId = useSelector(s => s.activeTeamId)
    const [loading, setLoading] = React.useState(true)
    const [shows, setShows] = React.useState([])

    React.useEffect(() => {
        if (!activeTeamId) return

        return firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/shows`)
            .onSnapshot(snap => {
                const shows = []
                snap.forEach(doc => shows.push({ id: doc.id, ...doc.data() }))
                setShows(shows)
                setLoading(false)
            })
    }, [activeTeamId])
    return { shows, loading }
}

export const useEpisodes = showId => {
    const activeTeamId = useSelector(s => s.activeTeamId)
    const [loading, setLoading] = React.useState(true)
    const [loadingMore, setLoadingMore] = React.useState(false)
    const [moreAvailable, setMoreAvailable] = React.useState(true)
    const [cursor, setCursor] = React.useState(null)
    const [episodes, setEpisodes] = React.useState([])
    const EP_FIRST_LIMIT = 20
    const EP_LIMIT = 50

    const loadMore = () => {
        let ref = firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/shows/${showId}/episodes`)
            .orderBy('releaseDate', 'desc')
        if (!activeTeamId) return

        setLoadingMore(true)

        if (cursor) ref = ref.startAfter(cursor)

        ref.limit(EP_LIMIT)
            .get()
            .then(snap => {
                const newEpisodes = []
                snap.forEach(doc => newEpisodes.push({ id: doc.id, ...doc.data() }))
                setEpisodes([...episodes, ...newEpisodes])
                setLoadingMore(false)
                if (snap.docs.length > 0) {
                    setCursor(snap.docs[snap.docs.length - 1])
                } else {
                    setMoreAvailable(false)
                }
            })
    }

    React.useEffect(() => {
        let ref = firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/shows/${showId}/episodes`)
            .orderBy('releaseDate', 'desc')
        if (!activeTeamId) return
        if (!showId) {
            console.error('Invalid showId')
            return
        }
        if (cursor) ref = ref.startAfter(cursor)

        ref.limit(EP_FIRST_LIMIT)
            .get()
            .then(snap => {
                const episodes = []
                snap.forEach(doc => {
                    episodes.push({ id: doc.id, ...doc.data() })
                })
                setEpisodes(episodes)
                setLoading(false)
                if (snap.docs.length > 0) {
                    setCursor(snap.docs[snap.docs.length - 1])
                } else {
                    setMoreAvailable(false)
                }
            })
    }, [activeTeamId, cursor, showId])
    return { episodes, loading, loadMore, loadingMore, moreAvailable }
}
export const useEpisode = (showId, epId) => {
    const activeTeamId = useSelector(s => s.activeTeamId)
    const [loading, setLoading] = React.useState(true)
    const [episode, setEpisode] = React.useState(null)

    React.useEffect(() => {
        if (!activeTeamId) return
        return firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/shows/${showId}/episodes`)
            .doc(epId)
            .onSnapshot(doc => {
                setEpisode({ ...doc.data(), id: doc.id })
                setLoading(false)
            })
    }, [activeTeamId, showId, epId])
    return { episode, loading }
}

export const subscribeMeta = () => {
    return (dispatch, getState) => {
        const { activeTeamId } = getState()
        if (!activeTeamId) return

        dispatch({ type: 'meta/reset' })

        const handler = firebase
            .firestore()
            .doc(`accounts/${activeTeamId}/meta/default`)
            .onSnapshot(doc => {
                let data = null
                if (doc.exists) {
                    data = doc.data()
                }
                dispatch({ type: 'meta/on_data', data })
            })

        //@Todo listeners should be added per activeTeamId
        dispatch({ type: 'listener/add', name: `meta`, handler })
    }
}
export const subscribeSettings = () => {
    return (dispatch, getState) => {
        const { activeTeamId } = getState()
        if (!activeTeamId) return

        dispatch({ type: 'settings/reset' })

        const handler = firebase
            .firestore()
            .doc(`accounts/${activeTeamId}/settings/default`)
            .onSnapshot(doc => {
                let data = null
                if (doc.exists) {
                    data = doc.data()
                }
                dispatch({ type: 'settings/on_data', data })
            })
        dispatch({ type: 'listener/add', name: `settings`, handler })
    }
}
export const subscribePlans = () => {
    return (dispatch, getState) => {
        const { activeTeamId } = getState()
        if (!activeTeamId) return

        dispatch({ type: 'plans/reset' })

        const handler = firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/plans`)
            .onSnapshot(snap => {
                const data = {}
                snap.forEach(doc => (data[doc.id] = { id: doc.id, ...doc.data() }))
                dispatch({ type: 'plans/on_data', data })
            })
        dispatch({ type: 'listener/add', name: `plans`, handler })
    }
}

export const usePaymentMethods = () => {
    const activeTeamId = useSelector(s => s.activeTeamId)
    const [loading, setLoading] = React.useState(true)
    const [methods, setMethods] = React.useState([])

    React.useEffect(() => {
        if (!activeTeamId) return

        return firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/payment_methods`)
            .onSnapshot(snap => {
                const methods = []
                snap.forEach(doc => {
                    methods.push({
                        ...doc.data(),
                        id: doc.id,
                    })
                })
                setMethods(methods)
                setLoading(false)
            })
    }, [activeTeamId])
    return { methods, loading }
}

export const subscribePaymentMethods = () => {
    return (dispatch, getState) => {
        const { activeTeamId } = getState()
        if (!activeTeamId) return

        dispatch({ type: 'payment_methods/reset' })

        const handler = firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/payment_methods`)
            .onSnapshot(snap => {
                const data = {}
                snap.forEach(doc => (data[doc.id] = { id: doc.id, ...doc.data() }))
                dispatch({ type: 'payment_methods/on_data', data })
            })
        dispatch({ type: 'listener/add', name: `payment_methods`, handler })
    }
}
export const subscribeTokenRefresh = () => {
    return (dispatch, getState) => {
        const { auth } = getState()
        if (!auth.data) return

        const handler = firebase
            .firestore()
            .doc(`accounts/${auth.data.id}/tokens/refreshed`)
            .onSnapshot(async doc => {
                const data = doc.data()
                firebase.auth().currentUser.getIdToken(true)
                dispatch({ type: 'refresh_token/on_data', data })
            })
        dispatch({ type: 'listener/add', name: `refresh_token`, handler })
    }
}

export const subscribeActivityFeed = () => {
    return (dispatch, getState) => {
        const { activeTeamId } = getState()
        if (!activeTeamId) return

        dispatch({ type: 'activity/reset' })

        dispatch(loadMoreActivity())

        const handler = firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/events`)
            .orderBy('created', 'desc')
            .limit(1) //1 so we always get the latest event
            .onSnapshot(
                snap => {
                    const data = {}
                    snap.forEach(doc => {
                        data[doc.id] = { id: doc.id, ...doc.data() }
                    })

                    dispatch({ type: 'activity/on_data', data })
                },
                error => {
                    dispatch({ type: 'activity/on_data', data: null })
                },
            )
        dispatch({ type: 'listener/add', name: `activity`, handler })
    }
}

export const createEpisode = (accountId, showId, data) => {
    return firebase
        .firestore()
        .collection(`accounts/${accountId}/shows/${showId}/episodes`)
        .add(data)
}

export const updateShow = (accountId, showId, data) => {
    return firebase
        .firestore()
        .doc(`accounts/${accountId}/shows/${showId}`)
        .set(data, { merge: true })
}
export const updateEpisode = (accountId, showId, epId, data) => {
    return firebase
        .firestore()
        .doc(`accounts/${accountId}/shows/${showId}/episodes/${epId}`)
        .set(data, { merge: true })
}
export const createShow = (data, accountId) => {
    return firebase.firestore().collection(`accounts/${accountId}/shows`).add(data)
}

export const subscribeCurrentMonthUsage = () => {
    return (dispatch, getState) => {
        const { activeTeamId } = getState()
        if (!activeTeamId) return

        dispatch({ type: 'current_usage/reset' })
        dispatch(loadPastInvoices())

        const handler = firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/usage`)
            .orderBy('periodEnd', 'desc')
            .limit(1)
            .onSnapshot(
                snap => {
                    let data
                    if (!snap.empty) {
                        const doc = snap.docs[0]
                        data = { id: doc.id, ...doc.data() }
                    }
                    dispatch({ type: 'current_usage/on_data', data })
                },
                error => {
                    dispatch({ type: 'current_usage/on_data', data: null })
                },
            )
        dispatch({ type: 'listener/add', name: `current_usage`, handler })
    }
}
export const loadPastInvoices = () => {
    return (dispatch, getState) => {
        const { activeTeamId, pastInvoices } = getState()
        if (!activeTeamId) return
        const PER_PAGE = 5

        dispatch({ type: 'past_invoices/fetch_start' })
        let ref = firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/invoices`)
            .orderBy('periodEnd', 'desc')

        if (pastInvoices.cursor) ref = ref.startAfter(pastInvoices.cursor)

        ref.limit(PER_PAGE)
            .get()
            .then(snap => {
                const data = {}
                snap.forEach(doc => {
                    data[doc.id] = { id: doc.id, ...doc.data() }
                })
                const payload = {
                    type: 'past_invoices/fetch_complete',
                    data,
                    moreAvailable: !(snap.docs.length < PER_PAGE),
                    cursor: pastInvoices.cursor,
                }
                if (snap.docs.length > 0) {
                    payload.cursor = snap.docs[snap.docs.length - 1]
                }
                dispatch(payload)
            })
            .catch(err => dispatch({ type: 'past_invoices/fetch_failed', err }))
    }
}
export const loadMoreActivity = () => {
    return (dispatch, getState) => {
        const { activeTeamId, activity } = getState()
        if (!activeTeamId) return
        const PER_PAGE = 20

        dispatch({ type: 'activity/fetch_start' })
        let ref = firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/events`)
            .orderBy('created', 'desc')

        if (activity.cursor) ref = ref.startAfter(activity.cursor)

        ref.limit(PER_PAGE)
            .get()
            .then(snap => {
                const data = {}
                snap.forEach(doc => {
                    data[doc.id] = { id: doc.id, ...doc.data() }
                })
                const payload = {
                    type: 'activity/fetch_complete',
                    data,
                    moreAvailable: !(snap.docs.length < PER_PAGE),
                    cursor: activity.cursor,
                }
                if (snap.docs.length > 0) {
                    payload.cursor = snap.docs[snap.docs.length - 1]
                }
                dispatch(payload)
            })
            .catch(err => dispatch({ type: 'activity/fetch_failed', err }))
    }
}

export const getPermissions = async teamId => {
    const tokenResult = await firebase.auth().currentUser.getIdTokenResult()
    try {
        const perms = tokenResult.claims.permissions
        console.log(perms)
        return perms[teamId]
    } catch (err) {
        //@Todo handle errors
        console.log('err', err.message)
        return {}
    }
}

export const subscribeTeams = () => {
    return (dispatch, getState) => {
        const { auth } = getState()
        if (!auth.data) return

        dispatch({ type: 'teams/reset' })

        const handler = firebase
            .firestore()
            .collection(`accounts/${auth.data.id}/teams`)
            .onSnapshot(snap => {
                const data = {}
                snap.forEach(doc => (data[doc.id] = { id: doc.id, ...doc.data() }))
                dispatch({ type: 'teams/on_data', data })
            })
        dispatch({ type: 'listener/add', name: `teams`, handler })
    }
}
export const subscribeShows = () => {
    return (dispatch, getState) => {
        const { activeTeamId } = getState()
        if (!activeTeamId) return

        dispatch({ type: 'shows/reset' })

        const handler = firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/shows`)
            .onSnapshot(
                snap => {
                    const data = {}
                    snap.forEach(doc => (data[doc.id] = { id: doc.id, ...doc.data() }))
                    dispatch({ type: 'shows/on_data', data })
                },
                err => {
                    dispatch({ type: 'shows/on_data', data: {} })
                },
            )

        dispatch({ type: 'listener/add', name: `shows`, handler })
    }
}
export const subscribeInvites = () => {
    return (dispatch, getState) => {
        const { activeTeamId } = getState()
        if (!activeTeamId) return

        const lName = `invites`

        dispatch({ type: 'invites/reset' })

        const handler = firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/invites`)
            .onSnapshot(snap => {
                const data = {}
                snap.forEach(doc => (data[doc.id] = { id: doc.id, ...doc.data() }))
                dispatch({ type: 'invites/on_data', data })
            })
        dispatch({ type: 'listener/add', name: lName, handler })
    }
}

//Get members in any team
export const subscribeMembers = () => {
    return (dispatch, getState) => {
        const { activeTeamId } = getState()
        if (!activeTeamId) return

        const lName = `members`

        dispatch({ type: 'members/reset' })

        const handler = firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/members`)
            .onSnapshot(snap => {
                const data = {}
                snap.forEach(doc => (data[doc.id] = { isMember: true, id: doc.id, ...doc.data() }))
                dispatch({ type: 'members/on_data', data })
            })
        dispatch({ type: 'listener/add', name: lName, handler })
    }
}
export const subscribeEpisodes = showId => {
    return (dispatch, getState) => {
        const { activeTeamId } = getState()
        if (!activeTeamId) return

        const lName = `episodes-${showId}`

        dispatch({ type: 'showEpisodes/reset', showId })

        const handler = firebase
            .firestore()
            .collection(`accounts/${activeTeamId}/shows/${showId}/episodes`)
            .orderBy('releaseDate', 'desc')
            .onSnapshot(snap => {
                const data = {}
                snap.forEach(doc => (data[doc.id] = { id: doc.id, ...doc.data() }))
                dispatch({ type: 'showEpisodes/on_data', showId, data })
            })
        dispatch({ type: 'listener/add', name: lName, handler })
    }
}
export const setCardDefault = id => {
    return async (dispatch, getState) => {
        dispatch({ type: 'payment_method/set_card_default_start', id })
        try {
            await api('card_set_default', { id })
            dispatch({ type: 'payment_method/set_card_default_complete', id })
        } catch (err) {
            dispatch({ type: 'payment_method/set_card_default_failed', id })
        }
    }
}
export const deleteCard = id => {
    return async (dispatch, getState) => {
        dispatch({ type: 'payment_method/delete_start', id })
        try {
            await api('card_remove', { id })
            dispatch({ type: 'payment_method/delete_complete', id })
        } catch (err) {
            dispatch({ type: 'payment_method/delete_failed', id })
        }
    }
}

let _handler
export const fetchAuth = () => {
    return dispatch => {
        dispatch({ type: 'auth/fetch_start' })

        //@Todo store unsubscribe handler in redux as well
        firebase.auth().onAuthStateChanged(
            async user => {
                //@Todo DANGER: this sends email every single time.
                //We should do this maybe once or twice
                //if(!user.emailVerified){
                // user.sendEmailVerification()
                //}

                let data = null
                if (user) {
                    if (!user.emailVerified) {
                        //Making sure we only send verification once.
                        const key = `verificationSent-${user.uid}`
                        if (!window.localStorage.getItem(key)) {
                            window.localStorage.setItem(key, true)
                            //@Todo handle failure
                            user.sendEmailVerification()
                        }
                    }
                    data = {
                        id: user.uid,
                        name: user.displayName,
                        email: user.email,
                        emailVerified: user.emailVerified,
                        isAnonymous: user.isAnonymous,
                        photoUrl: user.photoURL,
                    }
                    if (_handler) _handler()
                    _handler = firebase
                        .firestore()
                        .doc(`accounts/${user.uid}/tokens/refreshed`)
                        .onSnapshot(async doc => {
                            //@Todo we should not do this if refresh token has not changed
                            //probably use local storage?
                            firebase.auth().currentUser.getIdToken(true)
                        })

                    LogRocket.identify(user.uid, { name: user.displayName, email: user.email })
                } else {
                    //Reset localstorage
                    window.localStorage.setItem('activeTeamId', null)
                    if (_handler) _handler()
                }

                dispatch({ type: 'auth/fetch_complete', data })
            },
            error => {
                dispatch({ type: 'auth/fetch_failed', error })
            },
        )
    }
}

export const switchTeams = id => {
    return dispatch => {
        dispatch({ type: 'listeners/cleanup' })
        window.localStorage.setItem('activeTeamId', id)
        dispatch({ type: 'teams/switch', id })
        navigate('/')
    }
}
