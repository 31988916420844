import firebase from 'firebase/app'

const firebaseConfig = {
    apiKey: 'AIzaSyA65ztVODXm3QSHkDd_f_hKvGIjbKHxubg',
    authDomain: 'auth.mono.fm',
    databaseURL: 'https://monofm-prod.firebaseio.com',
    projectId: 'monofm-prod',
    storageBucket: 'monofm-prod.appspot.com',
    messagingSenderId: '838304148116',
    appId: '1:838304148116:web:91e99cb02c897b66446281',
    measurementId: 'G-3MKCL0Z0SP',
}

if (typeof window !== 'undefined') {
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig)
    }
}

export default firebase
