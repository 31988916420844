import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAuth } from '../state/actions.js'
import { navigate } from 'gatsby'

export const AuthProvider = props => {
    const dispatch = useDispatch()
    React.useEffect(() => {
        dispatch(fetchAuth())
    }, [dispatch])
    return props.children
}

export const useLoginRedirect = props => {
    const auth = useSelector(s => s.auth)

    const redirectTo = props.location.pathname || '/'
    React.useEffect(() => {
        if (auth.initting) return
        if (auth.loading) return

        if (!auth.data) {
            navigate('/login', { replace: true, state: { redirectTo } })
        }
    }, [redirectTo, auth])
    return null
}
