import React from 'react'
import { Provider } from 'react-redux'

import firebase from './src/lib/firebase'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'
import { store } from './src/state/createStore.js'
import { AuthProvider } from './src/hooks/auth.js'
import LogRocket from 'logrocket'
import './src/global.css'
import 'typeface-inter'

firebase.analytics()

export const wrapRootElement = ({ element }) => {
    return (
        <Provider store={store}>
            <AuthProvider>{element}</AuthProvider>
        </Provider>
    )
}

export const onServiceWorkerUpdateReady = () => {
    console.log('[INFO] Site update service worker update ready')
}

export const onClientEntry = () => {
    try {
        if (process.env.NODE_ENV !== 'development') {
            LogRocket.init('itpfkl/mono-app')
            LogRocket.getSessionURL(sessionURL => {
                window.Sentry.configureScope(scope => {
                    scope.setExtra('sessionURL', sessionURL)
                })
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const onServiceWorkerUpdateFound = () => {
    console.log('[INFO] Site update service worker update found')
    // if (
    //     window.confirm(
    //         'This site has been updated with new data. Do you wish to reload the site to get the new data?',
    //     )
    // ) {
    //     window.location.reload(true)
    // }
}
