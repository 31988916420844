import prettyBytes from 'pretty-bytes'
import { format, isFuture } from 'date-fns'
import _sanitizeHtml from 'sanitize-html'

export const isScheduledEpisode = episode => {
    if (!episode) return false
    const relDate = new Date(episode.releaseDate)
    return isFuture(relDate)
}
//Return the extension from a File Object
export const getExtensionFromFileObject = file => {
    if (!file || !file.name) {
        throw new Error('Invalid file object:', file)
    }
    return file.name.split('.').pop()
}

export const sanitizeHtml = raw => {
    let sanitzed
    try {
        sanitzed = _sanitizeHtml(raw)
    } catch (err) {
        console.warn('Err parsing html content:', err)
    }
    return sanitzed
}

export const parseErrorCode = code => {
    switch (code) {
        case 'auth/wrong-password':
            return 'Invalid email or password'
        case 'auth/user-not-found':
            return 'Invalid email or password'
        default:
            return 'Some error happened while logging in'
    }
}

export const truncate = (source, size = 30) => {
    try {
        return source.length > size ? source.slice(0, size - 1) + '…' : source
    } catch (err) {
        console.warn('Err truncating:', source, err)
        return source
    }
}
export const noop = () => {}

export const isUrl = str => {
    if (!str) return false

    let parsed = str.trim()
    return parsed.startsWith('http://') || parsed.startsWith('https://')
}

export const hasPermissions = (perms = [], key) => {
    let hasPerm = false
    perms.forEach(p => {
        if (p === key) hasPerm = true
    })
    return hasPerm
}
export const formatUrl = url => {
    try {
        if (url.startsWith('http://') || url.startsWith('https://')) {
            return url
        } else {
            return `http://${url}`
        }
    } catch (err) {}

    return url
}

export const formatDate = dateStr => {
    try {
        return format(new Date(dateStr), 'PP')
    } catch (err) {
        return dateStr
    }
}

export const formatUnix = timestamp => {
    return format(new Date(timestamp * 1000), 'PP')
}

export const validateBilling = (shows, paymentMethods, meta) => {
    if (
        meta.loading ||
        meta.error ||
        shows.loading ||
        shows.error ||
        paymentMethods.loading ||
        paymentMethods.error ||
        !meta.data
    )
        return true

    if (meta.data.subscriptionStatus === 'trialing') return true

    //If has a card return
    const cards = Object.values(paymentMethods.data)
    if (cards.length !== 0) return true

    //If no shows then okay
    const showsList = Object.values(shows.data)
    if (showsList.length === 0) return true

    return false
}
export const getBandwidthUsedFromUsage = usage => {
    if (!usage) return null

    const total = Object.values(usage.bytesSent).reduce((sum, el) => (sum += el), 0)
    return prettyBytes(total)
}
export const getAvgStorageUsedFromUsage = usage => {
    let total = Object.values(usage.bytesStored).reduce((sum, el) => (sum += el), 0)
    let average = 0

    if (total) {
        average = total / Object.values(usage.bytesStored).length
    }
    return prettyBytes(average)
}

export const trackError = err => {
    try {
        window.Sentry.captureException(err)
    } catch (err) {
        console.error(err)
    }
}
export const preventEnterSubmit = ev => {
    if (ev.which === 13) {
        ev.preventDefault()
    }
}
